import {
  Sellers,
  Seller,
  SellerGeneralInfo,
  SellerContactInfo,
  ContactInfo,
  SellerMarketingStatesResponse,
  SellersMarketingAllTagsResponse,
  SellerMarketingTagsResponse,
  SellerUsersInviteResponse,
  SellerInvitedUserResponse,
  SellerPoliciesResponse,
  SellerPoliciesUploadResponse,
} from 'shared/types/Sellers';

import { SELLERS_URL } from '../endpoints';
import request from '../request';

export default class SellersService {
  static readonly fetchSellers = () => {
    return request<Sellers>({
      url: SELLERS_URL,
      method: 'get',
    });
  };

  static readonly addSeller = (data: { email: string; title: string }) => {
    return request<Sellers>({
      url: SELLERS_URL,
      method: 'post',
      data,
    });
  };

  static readonly fetchSeller = (idOfSeller: string) => {
    return request<Seller>({
      url: `${SELLERS_URL}/${idOfSeller}`,
      method: 'get',
    });
  };

  static readonly fetchSellerGeneralInfo = (idOfSeller: string) => {
    return request<SellerGeneralInfo>({
      url: `${SELLERS_URL}/${idOfSeller}/general-info`,
      method: 'get',
    });
  };

  static readonly fetchSellerContactInfo = (idOfSeller: string) => {
    return request<SellerContactInfo>({
      url: `${SELLERS_URL}/${idOfSeller}/contact-info`,
      method: 'get',
    });
  };

  static readonly updateSellerGeneralInfo = ({
    idOfSeller,
    data,
  }: {
    idOfSeller: string;
    data: { [key: string]: string };
  }) => {
    return request<SellerGeneralInfo>({
      url: `${SELLERS_URL}/${idOfSeller}/general-info`,
      method: 'patch',
      data,
    });
  };

  static readonly updateSellerContactInfo = ({
    idOfSeller,
    data,
  }: {
    idOfSeller: string;
    data: { [key: string]: string };
  }) => {
    return request<ContactInfo>({
      url: `${SELLERS_URL}/${idOfSeller}/contact-info`,
      method: 'patch',
      data,
    });
  };

  static readonly updateSellerMarketingStates = ({
    idOfSeller,
    data,
  }: {
    idOfSeller: string;
    data: string[];
  }) => {
    return request<SellerMarketingStatesResponse>({
      url: `${SELLERS_URL}/${idOfSeller}/marketing-info/states`,
      method: 'post',
      data: { data },
    });
  };

  static readonly updateSellerMarketingTags = ({
    idOfSeller,
    data,
  }: {
    idOfSeller: string;
    data: { title: string }[];
  }) => {
    return request<SellerMarketingTagsResponse>({
      url: `${SELLERS_URL}/${idOfSeller}/marketing-info/tags`,
      method: 'post',
      data: { data },
    });
  };

  static readonly fetchSellersMarketingAllTags = () => {
    return request<SellersMarketingAllTagsResponse>({
      url: `${SELLERS_URL}/marketing-info/tags`,
      method: 'get',
    });
  };

  static readonly fetchSellerMarketingTags = (idOfSeller: string) => {
    return request<SellerMarketingTagsResponse>({
      url: `${SELLERS_URL}/${idOfSeller}/marketing-info/tags`,
      method: 'get',
    });
  };

  static readonly fetchSellerMarketingStates = (idOfSeller: string) => {
    return request<SellerMarketingStatesResponse>({
      url: `${SELLERS_URL}/${idOfSeller}/marketing-info/states`,
      method: 'get',
    });
  };

  static readonly sellerSendInviteUsers = ({
    idOfSeller,
    data,
  }: {
    idOfSeller: string;
    data: {
      firstName: string;
      lastName: string;
      email: string;
      phone?: string;
    };
  }) => {
    return request<SellerUsersInviteResponse>({
      url: `${SELLERS_URL}/${idOfSeller}/users/invite`,
      method: 'post',
      data,
    });
  };

  static readonly fetchSellerInvitedUsers = (idOfSeller: string) => {
    return request<SellerInvitedUserResponse>({
      url: `${SELLERS_URL}/${idOfSeller}/users`,
      method: 'get',
    });
  };

  static readonly fetchSellerPoliciesUploadUrl = (idOfSeller: string) => {
    return request<{ url: string }>({
      url: `${SELLERS_URL}/${idOfSeller}/upload-url`,
      method: 'get',
    });
  };

  static readonly fetchSellerPolicies = (idOfSeller: string) => {
    return request<SellerPoliciesResponse>({
      url: `${SELLERS_URL}/${idOfSeller}/policies`,
      method: 'get',
    });
  };

  static readonly uploadSellerPoliciesLink = ({
    idOfSeller,
    data,
  }: {
    idOfSeller: string;
    data: { [key: string]: string };
  }) => {
    return request<SellerPoliciesUploadResponse>({
      url: `${SELLERS_URL}/${idOfSeller}/policies`,
      method: 'patch',
      data,
    });
  };
}
