import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { Box, SelectChangeEvent } from '@mui/material';
import * as Yup from 'yup';
import { SellerGeneralInfo } from 'shared/types/Sellers';
import { EditCompanyInputModal } from 'shared/ui/EditCompanyInputModal';
import { EditableTextField } from 'shared/ui/EditableTextField';
import { generalFields, legalEntityTypes } from 'shared/constants/constants';

interface GeneralFormValues {
  title: string;
  legalEntityName: string;
  legalEntityType: string;
  companyMission: string;
}

const validationSchemaGeneral = Yup.object({
  title: Yup.string()
    .min(2, 'Must be at least 2 characters')
    .max(100, 'Must be 100 characters or less')
    .required('Required'),
  legalEntityName: Yup.string()
    .min(2, 'Must be at least 2 characters')
    .max(100, 'Must be 100 characters or less')
    .required('Required'),
  legalEntityType: Yup.string()
    .min(2, 'Must be at least 2 characters')
    .max(50, 'Must be 50 characters or less')
    .required('Required'),
  companyMission: Yup.string()
    .min(2, 'Must be at least 2 characters')
    .max(1000, 'Must be 1000 characters or less')
    .required('Required'),
});

export const GeneralInfoForm = ({
  generalInfo,
  handleUpdateGeneralInfo,
}: {
  generalInfo: SellerGeneralInfo;
  handleUpdateGeneralInfo: ({
    idOfSeller,
    data,
  }: {
    idOfSeller: string;
    data: { [key: string]: string };
  }) => void;
}) => {
  const { t } = useTranslation();
  const [isEditGeneral, setIsEditGeneral] = useState(false);
  const [fieldToEditGeneral, setFieldToEditGeneral] = useState<
    keyof GeneralFormValues | null
  >(null);
  const [tempValueGeneral, setTempValueGeneral] = useState<string>('');
  const [labelToEditGeneral, setLabelToEditGeneral] = useState<string>('');
  const [erroMessageGeneral, setErroMessageGeneral] = useState<string>('');
  const [isDropDownGeneral, setIsDropDownGeneral] = useState<boolean>(false);

  const formik = useFormik({
    initialValues: {
      title: generalInfo?.title || '',
      legalEntityName: generalInfo?.legalEntityName || '',
      legalEntityType: generalInfo?.legalEntityType || '',
      companyMission: generalInfo?.companyMission || '',
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      if (fieldToEditGeneral) {
        const updateData = { [fieldToEditGeneral]: values[fieldToEditGeneral] };
        handleUpdateGeneralInfo({
          idOfSeller: generalInfo.sellerId,
          data: updateData,
        });
      }
      setIsEditGeneral(false);
      setTempValueGeneral('');
    },
  });

  const handleEditGeneralClick = (
    fieldName: keyof GeneralFormValues,
    fieldLabel: string,
  ) => {
    setFieldToEditGeneral(fieldName);
    setLabelToEditGeneral(fieldLabel);
    setTempValueGeneral(formik.values[fieldName]);
    setIsDropDownGeneral(fieldName === 'legalEntityType');
    setIsEditGeneral(true);
  };

  const handleSaveFormikGeneral = useCallback(() => {
    if (
      !tempValueGeneral ||
      tempValueGeneral === generalInfo[fieldToEditGeneral]
    ) {
      setIsEditGeneral(false);
    } else if (fieldToEditGeneral) {
      formik.setFieldValue(fieldToEditGeneral, tempValueGeneral);
      formik.handleSubmit();
    }
  }, [fieldToEditGeneral, formik, tempValueGeneral, generalInfo]);

  const handleCloseModeEditGeneral = () => {
    setIsEditGeneral(false);
    setTempValueGeneral('');
    setErroMessageGeneral('');
  };

  const handleChangeGeneral = async (
    event:
      | React.ChangeEvent<{ name?: string; value: unknown }>
      | SelectChangeEvent<string>,
  ) => {
    const { value } = event.target;
    setTempValueGeneral(value as string);

    if (fieldToEditGeneral) {
      try {
        await validationSchemaGeneral.validateAt(fieldToEditGeneral, {
          [fieldToEditGeneral]: value,
        });
        setErroMessageGeneral('');
        formik.setFieldTouched(fieldToEditGeneral, true, false);
      } catch (err: any) {
        setErroMessageGeneral(err.errors[0]);
        formik.setFieldTouched(fieldToEditGeneral, true);
      }
    }
  };

  return (
    <Box mt={2}>
      {generalFields.map((field) => (
        <EditableTextField
          key={field.name}
          field={field}
          value={formik.values[field.name]}
          onEditClick={() => handleEditGeneralClick(field.name, field.label)}
          multiline={field.name === 'companyMission'}
        />
      ))}
      {isEditGeneral && (
        <EditCompanyInputModal
          isModalOpen={isEditGeneral}
          handleCloseModal={handleCloseModeEditGeneral}
          handleSubmitModal={handleSaveFormikGeneral}
          approveNameButton={t('Save')}
          label={labelToEditGeneral}
          fieldToEdit={fieldToEditGeneral}
          value={tempValueGeneral}
          error={Boolean(erroMessageGeneral)}
          helperText={erroMessageGeneral}
          isDropDown={isDropDownGeneral}
          options={legalEntityTypes}
          handleChange={handleChangeGeneral}
        />
      )}
    </Box>
  );
};
