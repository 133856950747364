import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box } from '@mui/material';
import { EditCompanyInputModal } from 'shared/ui/EditCompanyInputModal';
import { EditableTextField } from 'shared/ui/EditableTextField';
import { listOfStates } from 'shared/constants/states';

interface AddressInfoFormValues {
  ['address 1']: string;
  ['address 2']: string;
  city: string;
  state: string;
  ['zip code']: string;
  country: string;
}

const addressInfoFields: {
  name: keyof AddressInfoFormValues;
  label: string;
}[] = [
  { name: 'address 1', label: 'Address Line 1' },
  { name: 'address 2', label: 'Address Line 2' },
  { name: 'city', label: 'City' },
  { name: 'state', label: 'State' },
  { name: 'zip code', label: 'Postal Code' },
  { name: 'country', label: 'Country' },
];

const validationSchemaAddress = Yup.object({
  ['address 1']: Yup.string()
    .min(2, 'Must be at least 2 characters')
    .max(100, 'Must be 100 characters or less')
    .required('Required'),
  ['address 2']: Yup.string()
    .min(2, 'Must be at least 2 characters')
    .max(100, 'Must be 100 characters or less')
    .required('Required'),
  city: Yup.string()
    .min(2, 'Must be at least 2 characters')
    .max(50, 'Must be 50 characters or less')
    .required('Required'),
  state: Yup.string().required('Required'),
  ['zip code']: Yup.string()
    .matches(/^\d{5}(-\d{4})?$/, 'Please enter a valid postal code')
    .required('Required'),
  country: Yup.string().required('Required'),
});

export const AddressInfoForm = ({
  contactInfo,
  handleUpdateContactInfo,
}: {
  contactInfo: { [key: string]: string | null };
  handleUpdateContactInfo: ({
    idOfSeller,
    data,
  }: {
    idOfSeller: string;
    data: { [key: string]: string };
  }) => void;
}) => {
  const { t } = useTranslation();
  const [isEditAddress, setIsEditAddress] = useState(false);
  const [erroMessageAddress, setErroMessageAddress] = useState<string>('');
  const [fieldToEditAddress, setFieldToEditAddress] = useState<
    keyof AddressInfoFormValues | null
  >(null);
  const [tempValueAddress, setTempValueAddress] = useState<string>('');
  const [labelToEditAddress, setLabelToEditAddress] = useState<string>('');
  const [isDropDown, setIsDropDown] = useState<boolean>(false);

  const formik = useFormik<AddressInfoFormValues>({
    initialValues: {
      ['address 1']: contactInfo?.['address 1'] || '',
      ['address 2']: contactInfo?.['address 2'] || '',
      city: contactInfo?.city || '',
      state: contactInfo?.state || '',
      ['zip code']: contactInfo?.['zip code'] || '',
      country: contactInfo?.country || '',
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      if (fieldToEditAddress) {
        const updateData = {
          type: fieldToEditAddress,
          value: values[fieldToEditAddress],
        };
        handleUpdateContactInfo({
          idOfSeller: contactInfo.sellerId,
          data: updateData,
        });
      }
      setIsEditAddress(false);
      setTempValueAddress('');
    },
  });

  const handleEditAddressClick = (
    fieldName: keyof AddressInfoFormValues,
    fieldLabel: string,
  ) => {
    setFieldToEditAddress(fieldName);
    setLabelToEditAddress(fieldLabel);
    setTempValueAddress(formik.values[fieldName]);
    setIsDropDown(fieldName === 'state');
    setIsEditAddress(true);
  };

  const handleSaveFormikAddress = () => {
    if (
      !tempValueAddress ||
      tempValueAddress === contactInfo[fieldToEditAddress]
    ) {
      setIsEditAddress(false);
    } else if (fieldToEditAddress) {
      formik.setFieldValue(fieldToEditAddress, tempValueAddress);
      formik.handleSubmit();
    }
  };

  const handleCloseModeEditAddress = () => {
    setIsEditAddress(false);
    setTempValueAddress('');
    setErroMessageAddress('');
  };

  const handleChangeAddress = async (
    event: React.ChangeEvent<{ name?: string; value: unknown }>,
  ) => {
    const { value } = event.target;
    setTempValueAddress(value as string);

    if (fieldToEditAddress) {
      try {
        await validationSchemaAddress.validateAt(fieldToEditAddress, {
          [fieldToEditAddress]: value,
        });
        setErroMessageAddress('');
        formik.setFieldTouched(fieldToEditAddress, true, false);
      } catch (err: any) {
        setErroMessageAddress(err.errors[0]);
        formik.setFieldTouched(fieldToEditAddress, true);
      }
    }
  };

  return (
    <Box mt={2}>
      {addressInfoFields.map((field) => (
        <EditableTextField
          key={field.name}
          field={field}
          value={formik.values[field.name]}
          onEditClick={() => handleEditAddressClick(field.name, field.label)}
          disabled={field.name === 'country'}
        />
      ))}
      {isEditAddress && (
        <EditCompanyInputModal
          isModalOpen={isEditAddress}
          handleCloseModal={handleCloseModeEditAddress}
          handleSubmitModal={handleSaveFormikAddress}
          approveNameButton={t('Save')}
          value={tempValueAddress}
          error={Boolean(erroMessageAddress)}
          helperText={erroMessageAddress}
          isDropDown={isDropDown}
          options={listOfStates}
          label={labelToEditAddress}
          fieldToEdit={fieldToEditAddress}
          handleChange={handleChangeAddress}
        />
      )}
    </Box>
  );
};
