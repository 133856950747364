import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box } from '@mui/material';
import { useMask } from '@react-input/mask';
import { EditCompanyInputModal } from 'shared/ui/EditCompanyInputModal';
import { EditableTextField } from 'shared/ui/EditableTextField';
import { contactInfoFields } from 'shared/constants/constants';

interface ContactInfoFormValues {
  phone: string;
  email: string;
  ['company website']: string;
}

const validationSchemaContact = Yup.object({
  phone: Yup.string()
    .matches(/[+\d]$/, 'Please enter a valid phone number')
    .required('Required'),
  email: Yup.string()
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      'Please enter a valid email address',
    )
    .required('Required'),
  ['company website']: Yup.string()
    .url('Please enter a valid URL, e.g. https://example.com')
    .required('Required'),
});

export const ContactInfoForm = ({
  contactInfo,
  handleUpdateContactInfo,
}: {
  contactInfo: { [key: string]: string | null };
  handleUpdateContactInfo: ({
    idOfSeller,
    data,
  }: {
    idOfSeller: string;
    data: { [key: string]: string };
  }) => void;
}) => {
  const { t } = useTranslation();
  const [isEditContact, setIsEditContact] = useState(false);
  const [erroMessageContact, setErroMessageContact] = useState<string>('');
  const [fieldToEditContact, setFieldToEditContact] = useState<
    keyof ContactInfoFormValues | null
  >(null);
  const [tempValueContact, setTempValueContact] = useState<string>('');
  const [labelToEditContact, setLabelToEditContact] = useState<string>('');
  const [placeholderToEditContact, setPlaceholderToEditContact] =
    useState<string>('');

  const inputPhoneRef = useMask({
    mask: '+1 (___) ___-____',
    replacement: { _: /\d/ },
    showMask: true,
    separate: true,
  });

  const formik = useFormik<ContactInfoFormValues>({
    initialValues: {
      phone: contactInfo?.phone || '',
      email: contactInfo?.email || '',
      ['company website']: contactInfo?.['company website'] || '',
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      if (fieldToEditContact) {
        const updateData = {
          type: fieldToEditContact,
          value: values[fieldToEditContact],
        };
        handleUpdateContactInfo({
          idOfSeller: contactInfo.sellerId,
          data: updateData,
        });
      }
      setIsEditContact(false);
      setTempValueContact('');
    },
  });

  const handleEditContactClick = (
    fieldName: keyof ContactInfoFormValues,
    fieldLabel: string,
    fieldPlaceholder: string,
  ) => {
    setFieldToEditContact(fieldName);
    setLabelToEditContact(fieldLabel);
    setPlaceholderToEditContact(fieldPlaceholder);
    setTempValueContact(formik.values[fieldName]);
    setIsEditContact(true);
  };

  const handleSaveFormikContact = () => {
    if (
      !tempValueContact ||
      tempValueContact === contactInfo[fieldToEditContact]
    ) {
      setIsEditContact(false);
    } else if (fieldToEditContact) {
      formik.setFieldValue(fieldToEditContact, tempValueContact);
      formik.handleSubmit();
    }
  };

  const handleCloseModeEditContact = () => {
    setIsEditContact(false);
    setTempValueContact('');
    setErroMessageContact('');
  };

  const handleChangeContact = async (
    event: React.ChangeEvent<{ name?: string; value: unknown }>,
  ) => {
    const { value } = event.target;
    setTempValueContact(value as string);

    if (fieldToEditContact) {
      try {
        await validationSchemaContact.validateAt(fieldToEditContact, {
          [fieldToEditContact]: value,
        });
        setErroMessageContact('');
        formik.setFieldTouched(fieldToEditContact, true, false);
      } catch (err: any) {
        setErroMessageContact(err.errors[0]);
        formik.setFieldTouched(fieldToEditContact, true);
      }
    }
  };

  return (
    <Box mt={2}>
      {contactInfoFields.map((field) => (
        <EditableTextField
          key={field.name}
          field={field}
          value={formik.values[field.name]}
          isLink={field.name === 'company website'}
          onEditClick={() =>
            handleEditContactClick(field.name, field.label, field.placeholder)
          }
        />
      ))}
      {isEditContact && (
        <EditCompanyInputModal
          maskRef={fieldToEditContact == 'phone' ? inputPhoneRef : null}
          isModalOpen={isEditContact}
          handleCloseModal={handleCloseModeEditContact}
          handleSubmitModal={handleSaveFormikContact}
          approveNameButton={t('Save')}
          value={tempValueContact}
          error={Boolean(erroMessageContact)}
          helperText={erroMessageContact}
          label={labelToEditContact}
          fieldToEdit={fieldToEditContact}
          handleChange={handleChangeContact}
          placeholder={placeholderToEditContact}
        />
      )}
    </Box>
  );
};
