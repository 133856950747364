import { memo, useState, useMemo } from 'react';
import {
  Box,
  Button,
  Skeleton,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import useSellerInviteUsers from 'pages/SellerPage/hooks/useSellerInviteUsers';
import { InviteUserFormModal } from './InviteUserFormModal';

export const UsersInfoPage = memo(() => {
  const { t } = useTranslation();
  const {
    sellerSendInviteUsers,
    sellerId,
    isLoadingInvitedUsers,
    listInvitedUsers,
  } = useSellerInviteUsers();
  const [isOpenAddUserModal, setIsOpenAddUserModal] = useState(false);

  const tableHeaderData = useMemo(
    () => [
      { label: t('Name'), value: 'name' },
      { label: t('Email'), value: 'email' },
      { label: t('Status'), value: 'status' },
    ],
    [t],
  );

  return (
    <Box
      flex="1"
      mt={1}
      p="16px"
      bgcolor="grey.300"
      borderRadius="12px"
      display="flex"
      flexDirection="column"
    >
      <Box display="flex" justifyContent="flex-end" alignItems="flex-start">
        <Button
          sx={{ width: '212px' }}
          variant="contained"
          onClick={() => setIsOpenAddUserModal(true)}
        >
          {t('Invite User')}
        </Button>
      </Box>
      <TableContainer sx={{ marginTop: '20px' }}>
        <Table>
          <TableHead>
            <TableRow>
              {tableHeaderData.map((item) => (
                <TableCell key={item.value}>
                  <Typography
                    sx={{
                      width: '100%',
                      display: 'block',
                    }}
                    typography={'openSans.captionMedium'}
                  >
                    {item.label}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!isLoadingInvitedUsers &&
              (listInvitedUsers && listInvitedUsers.length > 0 ? (
                listInvitedUsers.map((user) => (
                  <TableRow key={user.email}>
                    <TableCell>
                      <Typography typography={'openSans.body2'}>
                        {`${user.lastName}, ${user.firstName}`}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography typography={'openSans.caption'}>
                        <a
                          href={`mailto:${user.email}`}
                          style={{ textDecoration: 'none', color: '#5492F4' }}
                        >
                          {user.email}
                        </a>
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography typography={'openSans.body2'}>
                        {user.status}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={3}>
                    <Typography
                      typography={'openSans.body2'}
                      align="center"
                      color="grey.600"
                    >
                      {t('You have not invited any users')}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {isLoadingInvitedUsers && (
        <Box display="flex" flexDirection="column" gap="2px">
          {Array.from({ length: 5 }).map((_, index) => (
            <Skeleton key={index} variant="rectangular" height="53px" />
          ))}
        </Box>
      )}
      {isOpenAddUserModal && (
        <InviteUserFormModal
          sellerId={sellerId}
          isOpenAddUserModal={isOpenAddUserModal}
          setIsOpenAddUserModal={setIsOpenAddUserModal}
          sellerSendInviteUsers={sellerSendInviteUsers}
        />
      )}
    </Box>
  );
});
