import React from 'react';
import {
  Box,
  TextField,
  IconButton,
  Link,
  InputAdornment,
  Typography,
} from '@mui/material';
import { ModeEditOutlineOutlined } from '@mui/icons-material';
import { InfoTooltip } from 'shared/ui/Tooltip';

interface EditableTextFieldProps {
  field: { name: string; label: string; tooltip?: string };
  value: string;
  onEditClick: () => void;
  multiline?: boolean;
  disabled?: boolean;
  isLink?: boolean;
}

export const EditableTextField = ({
  field,
  value,
  onEditClick,
  multiline = false,
  disabled = false,
  isLink = false,
}: EditableTextFieldProps) => {
  const showingValue = value || 'No information provided';
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        width: '100%',
        '&:hover .editIconButton': {
          visibility: 'visible',
        },
      }}
      mb={2}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
        <TextField
          disabled={disabled}
          fullWidth
          variant="standard"
          multiline={multiline}
          minRows={multiline ? 1 : undefined}
          label={
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
              {field.label}
              {field.tooltip && <InfoTooltip content={field.tooltip} />}
            </Box>
          }
          value={isLink && value ? '' : showingValue}
          sx={{
            '& .MuiInputBase-root.MuiInput-root:not(.Mui-disabled, .Mui-error):before':
              {
                borderBottom: '1px solid rgba(0, 0, 0, 0.42) !important',
              },
            '& .MuiInputBase-root.MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before':
              {
                borderBottom: '1px solid rgba(0, 0, 0, 0.42) !important',
              },
            '& .MuiInputBase-root.MuiInput-root:not(.Mui-disabled, .Mui-error):after':
              {
                borderBottom: '1px solid rgba(0, 0, 0, 0.42) !important',
              },
            '& .MuiInputBase-root.MuiInput-root:hover:not(.Mui-disabled, .Mui-error):after':
              {
                borderBottom: '1px solid rgba(0, 0, 0, 0.42) !important',
              },
            input: {
              ...(!value ? { color: 'black', opacity: 0.5 } : {}),
            },
            textarea: {
              ...(!value ? { color: 'black', opacity: 0.5 } : {}),
            },
          }}
          inputProps={
            !multiline
              ? {
                  style: {
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  },
                }
              : {}
          }
          InputProps={{
            style: {
              paddingRight: '40px',
            },
            readOnly: true,
            startAdornment:
              isLink && value ? (
                <InputAdornment position="start">
                  <Link href={value} target="_blank" underline="hover">
                    <Typography
                      sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {value}
                    </Typography>
                  </Link>
                </InputAdornment>
              ) : null,
            endAdornment: (
              <IconButton
                disabled={disabled}
                sx={{
                  position: 'absolute',
                  right: 0,
                  top: '36%',
                  transform: 'translateY(-50%)',
                  visibility: 'hidden',
                }}
                className="editIconButton"
                onClick={onEditClick}
              >
                <ModeEditOutlineOutlined color="primary" />
              </IconButton>
            ),
          }}
        />
      </Box>
    </Box>
  );
};
